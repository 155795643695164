
import * as unsplashRuntime$yzSd3pdkcL from '/home/bun/app/node_modules/@nuxt/image/dist/runtime/providers/unsplash'
import * as pexelsRuntime$aygWYlxMhx from '/home/bun/app/node_modules/@nuxt/image/dist/runtime/providers/imgix'
import * as awsRuntime$aygWYlxMhx from '/home/bun/app/node_modules/@nuxt/image/dist/runtime/providers/imgix'
import * as ipxRuntime$das3yd392X from '/home/bun/app/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['unsplash']: { provider: unsplashRuntime$yzSd3pdkcL, defaults: undefined },
  ['pexels']: { provider: pexelsRuntime$aygWYlxMhx, defaults: undefined },
  ['aws']: { provider: awsRuntime$aygWYlxMhx, defaults: {"baseURL":"https://lbmx.imgix.net"} },
  ['ipx']: { provider: ipxRuntime$das3yd392X, defaults: {} }
}
        